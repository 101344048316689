body {
  // prevent hover effect from extending the page width
  overflow-x: hidden;
}

.landing {
  background: #0d0c18;
  min-height: 100vh;
  margin: 0 auto;
  align-items: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  svg {
    fill: white;
  }

  button {
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;

    height: 36px;
    padding: 0 16px;
    background: transparent;
    border: 2px solid white;
    color: white;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.primary {
      background: white;
      color: #1b1631;
    }
    &.gray {
      background: #322f3f;
      border: none;
      box-shadow: inset 0px -6px 12px rgba(255, 255, 255, 0.1);
    }
    &.colors {
      background: linear-gradient(
        269.15deg,
        #ff9900 -24.13%,
        #ff2f65 52.4%,
        #af31fd 116.75%
      );

      box-shadow: inset 0px 2px 6px #ffffff;
      border: none;
      font-weight: 600;
    }

    &.hamburgerBtn {
      border: none !important;
      padding: 0 !important;
      margin: 0;
      position: relative;
      height: 24px;
      width: 24px;

      > div {
        border: none;
        width: 24px;
        height: 2px;
        margin: 0 auto;
        background: white;
        position: absolute;
      }
      &:not(.open) > div {
        &:nth-child(1) {
          top: 20px;
        }
        &:nth-child(2) {
          opacity: 1;
          margin-bottom: 0;
        }
        &:nth-child(3) {
          bottom: 20px;
        }
      }
      &.open {
        > div {
          &:nth-child(1) {
            transform: rotate(-45deg);
            // margin: 0 auto;
            top: 11px;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(45deg);
            // margin: 0 auto;
            bottom: 11px;
          }
        }
      }
    }
  }

  a {
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
  }

  label {
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.33);
  }

  h1 {
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 4px 28px #0d0c18;
    font-size: 58px;
    line-height: 64px;
    font-weight: 700;
  }

  h2 {
    color: rgba(255, 255, 255, 1);
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
  }

  h3 {
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  h4 {
    color: #cbcacd;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-shadow: 0 0 28px #0d0c18;
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }

  span {
    background: radial-gradient(
      167.95% 8413.68% at 2.69% -67.95%,
      #cc00ff 0%,
      #0075ff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  input {
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;

    background: #0d0c18b5;
    backdrop-filter: blur(15px);

    height: 42px;
    padding: 0 16px;

    border: 2px solid #322f3f;
    background: transparent;
    color: white;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  footer {
    border-top: 2px solid rgba(255, 255, 255, 0.1);
  }

  &-card {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;

    margin: 42px;
    width: calc(100% - 40px);
    position: relative;
    align-content: stretch;
    overflow: hidden;

    .img {
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;

      height: 100%;
      overflow: hidden;
      min-width: 400px;
      position: relative;
      box-shadow: 0 8px 48px 0 rgba(0, 0, 0, 0.2);
    }
  }
  &-transition {
    transition: all 400ms;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
  }
  &-splash {
    min-height: 720px;
    max-height: 880px;
    position: relative;

    &-img-container {
      max-height: 940px;
      height: 100%;
      width: 100%;
      background-size: contain;
      background-position: center right;
      background-repeat: no-repeat;
    }
  }
  &-header {
    position: fixed;
    top: 0;
    // left: 0;
    z-index: 100;
    height: 72px;
    background: #0d0c18b5;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(100px);

    &-inner {
      z-index: 101;
      height: 72px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      width: calc(100% - 40px);

      svg {
        height: 24px;
      }

      a {
        text-transform: uppercase;
        cursor: default;
        line-height: 72px;
        &:hover {
          box-shadow: 0 1px 0 0 rgba(255, 255, 255, 1);
        }
      }
    }

    &-mobile {
      position: fixed;
      box-sizing: border-box;
      background: #1b1631;
      top: 0;
      z-index: 99;
      padding: 92px 20px 12px 20px;
      max-height: 0;
      opacity: 0;
      width: 100%;

      &.open {
        max-height: 300px;
        opacity: 1;
      }
    }
  }
  &-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(248px, auto));
    grid-gap: 16px;

    > div {
      transition: all 200ms;
      -webkit-transition: all 200ms;
      -moz-transition: all 200ms;

      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;

      border-radius: 12px;
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      padding: 24px;

      border: 2px solid rgba(255, 255, 255, 0.1);

      color: rgba(255, 255, 255, 1);
      font-size: 20px;
      font-weight: 700;

      &:hover {
        border-color: white;
        cursor: default;
      }
    }
  }
  &-feature-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10%;

    svg {
      height: 48px;
      fill: white;
    }
  }
  &-supporters {
    bottom: 64px;
    position: absolute;
    margin: 0 auto;

    svg {
      height: 80px;
      fill: rgba(255, 255, 255, 0.66);
    }
  }
  &-hover-effect {
    height: 150px;
    width: 150px;
    position: absolute;
    border-radius: 1000px;
    mix-blend-mode: darken;

    pointer-events: none;

    background: radial-gradient(
      33.91% 33.24% at 51.13% 49.09%,
      rgba(255, 153, 0, 0.5) 0%,
      #e53b80 29.69%,
      rgba(175, 49, 253, 0) 100%
    );
  }
  &-textblurb {
    &1 {
      margin-right: 100px;
      max-width: 640px;
      margin-top: 100px;
    }
    &2 {
      margin-left: 100px;
      max-width: 640px;
      margin-top: 100px;
    }
  }
  &-investors {
    overflow: hidden;

    h1 {
      text-align: center;
    }
    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px 48px;
    }
  }

  .pill {
    border: 2px solid #d5d5d5;
    color: white;
    border-radius: 90px;
    padding: 2px 10px;
    width: fit-content;
    float: right;
    font-size: small;
  }

  .find-img-logo {
    width: 38px;
  }

  .show-on-mobile {
    display: none;
  }
  .hide-on-mobile {
    display: inherit;
  }
  .light-bg {
    background: rgba(255, 255, 255, 0.05);
  }

  @media only screen and (max-width: 800px) {
    .align-center {
      text-align: center;
    }
    .row-sb-c.mobile-shift,
    .row-sb.mobile-shift,
    .row-fe-c.mobile-shift,
    .col.mobile-shift,
    .row-fs-c.mobile-shift,
    .row-c-c.mobile-shift,
    .row.mobile-shift {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.reverse {
        flex-direction: column-reverse;
      }
    }
    .landing-textblurb {
      &1 {
        margin-right: 0;
        max-width: 640px;
        margin-top: 0;
      }
      &2 {
        margin-left: 0;
        max-width: 640px;
        margin-top: 0;
      }
    }
    .show-on-mobile {
      display: inherit;
    }
    .hide-on-mobile {
      display: none;
    }
    .landing-feature-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10%;
    }
    .landing-splash-img-container {
      background-position: center bottom;
      &::after {
        display: block;
        content: "";
        position: absolute;
        background: linear-gradient(#0d0c18, transparent);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
}
.blog {
  background: #0d0c18;
  padding-top: 72px;
  h2,
  h3,
  h4 {
    margin: 1rem 0;
  }
  a {
    cursor: pointer;
    &:hover {
      opacity: 0.66;
    }
  }
  p {
    color: #f9f6ff;
    line-height: 1.8;
    font-weight: 400;
    font-size: 17px;
    a {
      box-shadow: 0 1px 0 0 #f9f6ff;
    }
  }
  &-grid {
    width: calc(100% - 40px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    &-card {
      transition: all 200ms;
      -webkit-transition: all 200ms;
      -moz-transition: all 200ms;

      &:hover {
        transform: scale(1.05);
        filter: brightness(1.25);
      }
      &-img {
        border-radius: 8px;
        height: 200px;
        width: 100%;
        // padding: 12px;
        // background: linear-gradient(
        //   180deg,
        //   rgba(27, 22, 49, 0.25) 0%,
        //   rgba(108, 74, 255, 0.25) 100%
        // );
        box-shadow: 0px 12px 20px -10px rgba(0, 0, 0, 0.5);

        > div {
          border-radius: 4px;
          height: 100%;
          width: 100%;
          border: 2px solid rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
  p {
    margin: 2rem 0;
  }

  @media only screen and (max-width: 800px) {
    .blog-grid {
      grid-template-columns: 1fr;
    }
  }
}

.space-h {
  width: 10px;
}

.space-v {
  height: 10px;
}

.landing .pad-64 {
  padding: 64px;
}

.row-sb-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.col-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row-fs-c {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-fe-c {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.row-c-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.max {
  &-1080 {
    max-width: 1280px;
    margin: 0 20px;
    width: calc(100% - 40px);
  }
  &-880 {
    max-width: 880px;
    margin: 0 20px;
    width: calc(100% - 40px);
  }
  &-720 {
    max-width: 720px;
    margin: 0 20px;
    width: calc(100% - 40px);
  }
  &-w {
    width: 100%;
  }
  &-h {
    height: 100%;
  }
  &-vw {
    width: 100vw;
  }
  &-vh {
    height: 100vh;
  }
}

.legal {
  text-align: left;
}

.legal-body {
  max-width: 50%;
  h2 {
    font-size: large;
  }
  p {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .landing {
    h1 {
      font-size: 42px;
      line-height: 48px;
    }
    &-investors {
      max-width: 80%;
    }
  }

  .legal-body {
    max-width: 80%;
  }
}
