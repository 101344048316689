.ProseMirror {
  padding-bottom: 16px;
  margin: 4px 6px;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  font-weight: 600;
}

.ProseMirror.ProseMirror-focused[contenteditable] {
  outline: none;
}

.ProseMirror a,
.TipTapText a {
  font-weight: 500;
  color: rgb(0, 153, 255);
}
